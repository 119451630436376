import LocaleContainer from "@components/Locale";
import React from "react";
import ReactDOM from "react-dom";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { loadIcons } from "ysd-pp/es/icon";
import ModelsProvider from "./components/useModel/Provider";
import "./index.less";
import { models } from "./models/index";
import routes from "./routes/ms";

loadIcons(() => {
    import("@/assets/Icon/iconfont.ts");
});
const router = createHashRouter(routes);

ReactDOM.render(
    <React.StrictMode>
        <LocaleContainer>
            <ModelsProvider models={models}>
                <RouterProvider router={router} />
            </ModelsProvider>
        </LocaleContainer>
    </React.StrictMode>,
    document.getElementById("root")
);
